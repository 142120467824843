<template>
  <article class="bg-white">
    <h3 class="text-center">{{ $t("selectTip") }}</h3>
    <section class="options-percent__container">
      <button
        role="button"
        v-for="({ label, subLabel, value, active }, index) in optionsPercent"
        :key="index"
        :class="`btn btn-hover${active ? ' active' : ''}`"
        @click="selectTip(value, Number(subLabel).toFixed(2))"
      >
        <h3>{{ label }}</h3>
        {{ (Number(subLabel) && calculateTipPercent(subLabel)) || subLabel }}
      </button>
    </section>
    <CustomAmountTipInput v-if="showInput" />
    <button class="btn-noTips" role="button" @click="handlerNoTip">
      {{ $t("noTip") }}
    </button>
  </article>
</template>
<script>
import { formatPrice } from "@/helpers";
import { i18n } from "@/plugins/i18n";

export default {
  name: "SelectTip",
  components: {
    CustomAmountTipInput: () => import("./CustomAmountTipInput.vue"),
  },
  props: {
    totalToPay: { type: Number },
  },
  data() {
    return {
      showInput: false,
      tipAmount: "",
      optionsPercent: [
        {
          label: "5%",
          value: "5%",
          subLabel: 0.05,
          active: false,
        },
        {
          label: "10%",
          value: "10%",
          subLabel: 0.1,
          active: false,
        },
        {
          label: i18n.t("otherTip"),
          value: "other",
          subLabel: "",
          active: false,
        },
      ],
    };
  },
  mounted() {
    const optSelected = this.getOptTipSelected;
    if (optSelected === "no_tip") return;

    const optFound = this.optionsPercent.find(
      ({ value }) => value === optSelected
    );

    optFound && (optFound.active = true);
    if (this.getOptTipSelected !== "other") return;

    this.showInput = true;
  },
  methods: {
    formatPrice,
    calculateTipPercent(percent) {
      return this.formatPrice(this.totalToPay * percent);
    },
    selectTip(option, percent) {
      this.optionsPercent.map((opt) => {
        opt.active = option === opt.value;
      });
      if (option === "other") {
        this.showInput = true;
        this.$store.dispatch("cart/setTip", {
          amount: this.tipAmount || 0,
          option,
        });
        return;
      }

      this.tipAmount = "";
      this.showInput = false;

      const amount = percent ? (this.totalToPay * percent).toFixed(2) : 0;
      this.$store.dispatch("cart/setTip", { amount, option });
    },
    handlerNoTip() {
      this.selectTip("no_tip");
      this.$emit("noTipSelected");
    },
  },
  computed: {
    getOptTipSelected() {
      return this.$store.getters["cart/getTipInfo"].option;
    },
    getAmountTipSelected() {
      return this.$store.getters["cart/getTipInfo"].amount;
    },
  },
};
</script>

<style lang="scss" scoped>
article {
  background: #fff;
  border-radius: 20px;
  padding: 5px 10px;
}
.title {
  font-size: 14px;
}
.options-percent__container {
  margin: 15px auto;
  text-align: center;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  .btn-hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: auto;
    font-size: 14px;
    font-weight: 800;
    color: #fff;
    cursor: pointer;
    height: 80px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 24px;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-position: 100% 0;
      translate: 2px 2px;
      moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    &:focus {
      outline: none;
    }
    &.btn {
      background-image: linear-gradient(
        to right,
        #29323c,
        #485563,
        #2b5876,
        #4e4376
      );
      box-shadow: 10px 8px 0px rgb(191 219 254);
      padding: 10px;
    }
    &.active {
      translate: 4px 4px;
      background-image: linear-gradient(to right, #f5af19, #f12711);
      box-shadow: 8px 4px 0px rgb(191 219 254);
      cursor: default;
      &:hover {
        background-position: 0 0;
      }
    }
  }
}
.btn-noTips {
  width: 100%;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 8px;
  padding: 14px 24px;
  border: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s,
    -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  background: linear-gradient(
    to right,
    rgb(230, 30, 77) 0%,
    rgb(227, 28, 95) 50%,
    rgb(215, 4, 102) 100%
  );
  color: #fff;
}
</style>
